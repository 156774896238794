// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/footer/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/footer/Footer.tsx");
  import.meta.hot.lastModified = "1715348872065.889";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
const navigation = {
  support: [{
    page: 'help',
    href: '#'
  }, {
    page: 'trackOrder',
    href: '#'
  }, {
    page: 'shipping',
    href: '#'
  }, {
    page: 'returns',
    href: '#'
  }],
  company: [{
    page: 'about',
    href: '#'
  }, {
    page: 'blog',
    href: '#'
  }, {
    page: 'responsibility',
    href: '#'
  }, {
    page: 'press',
    href: '#'
  }]
};
export default function Footer({
  collections
}) {
  _s();
  const {
    t
  } = useTranslation();
  return <footer className="mt-24 border-t bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        {t('footer.title')}
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  {t('footer.shop')}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {collections.map(collection => <li key={collection.id}>
                      <Link className="text-base text-gray-500 hover:text-gray-600" to={'/collections/' + collection.slug} prefetch="intent" key={collection.id}>
                        {collection.name}
                      </Link>
                    </li>)}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  {t('footer.support')}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map(({
                  page,
                  href
                }) => <li key={page}>
                      <a href={href} className="text-base text-gray-500 hover:text-gray-600">
                        {t(`navigation.support.${page}`)}
                      </a>
                    </li>)}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  {t('account.company')}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map(({
                  page,
                  href
                }) => <li key={page}>
                      <a href={href} className="text-base text-gray-500 hover:text-gray-600">
                        {t(`navigation.company.${page}`)}
                      </a>
                    </li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
              {t('footer.subscribeHeader')}
            </h3>
            <p className="mt-4 text-base text-gray-500">
              {t('footer.subscribeIntro')}
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                {t('acount.emailAddress')}
              </label>
              <input type="email" name="email-address" id="email-address" autoComplete="email" required className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400" placeholder={t('footer.emailPlaceholder')} />
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" className="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500">
                  {t('footer.subscribe')}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-8 border-t pt-8">
          <a className="flex items-center space-x-4 font-medium text-gray-500 hover:text-gray-700" href="https://github.com/vendure-ecommerce/storefront-remix-starter">
            <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
              <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
            </svg>
            <span>github.com/vendure-ecommerce/storefront-remix-starter</span>
          </a>
        </div>
      </div>
    </footer>;
}
_s(Footer, "zlIdU9EjM2llFt74AbE2KsUJXyM=", false, function () {
  return [useTranslation];
});
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;